import en from './en'
import pl from './pl'
import jp from './jp'

export const dictionaryList = {
    en,
    pl,
    // jp
} 

export const languageOptions = [
    {id: 'en', text: 'English'},
    {id: 'pl', text: 'Polski'},
    // {id: 'jp', text: '日本語'}
]